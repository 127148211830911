.what-about-us {
  padding: 80px 0;
  // .what-about-us__swiper
  @media (max-width: 1440px) {
    padding: 40px 0;
  }
  &__swiper {
    display: flex;
    align-items: center;
    margin: 0 auto;
    width: 87%;
  }
}
.what-about-us-swiper {
  &.swiper {
    border-radius: 20px;
  }
  // .what-about-us-swiper__discription

  &__discription {
    position: relative;
    width: 42%;
    @media (max-width: 1000px) {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      padding-bottom: 32px;
    }
    @media (max-width: 768px) {
      padding-bottom: 16px;
    }
  }

  // .what-about-us-swiper__title

  &__title {
    font-weight: 900 !important;
    letter-spacing: 0.03em;
    color:var(--ITSports-color-font);
    font-size: 48px;
    line-height: 140%;
    text-transform: uppercase;
    padding-bottom: 16px;
    @media (max-width: 1440px) {
      font-size: 40px;
      line-height: 140%;
      font-weight: 700;
    }
    @media (max-width: 1000px) {
      font-size: 32px;
    }
    @media (max-width: 768px) {
      font-size: 26px;
    }
  }

  // .what-about-us-swiper__text

  &__text {
    color:var(--ITSports-color-font);

    font-size: 20px;
    line-height: 26px;
    font-weight: 500;
    letter-spacing: 1.4px;
    width: 500px;
    @media (max-width: 1440px) {
      font-size: 18px;
      line-height: 24px;
      font-weight: 500;
      width: 360px;
    }

    @media (max-width: 1000px) {
      width: 750px;
      font-size: 16px;
    }
    @media (max-width: 768px) {
      width: 400px;
      font-size: 15px;
    }
    @media (max-width: 768px) {
      display: none;
    }
  }
}

.what-about-us-swiper {
  flex-wrap: wrap;
}

.what-about-us-swiper.swiper {
  width: 58%;
  @media (max-width: 768px) {
    width: 100%;
  }
  @media (max-width: 768px) {
    display: none;
  }
}

.swiper-buttons {
  padding-top: 32px;
  display: flex;
  align-items: center;
  width: 500px;
  @media (max-width: 1440px) {
    width: 350px;
  }
  @media (max-width: 768px) {
    display: none;
  }

  justify-content: space-between;
}
.swiper-button-next {
  &.what-about-us-swiper {
    position: relative;
    background-color: var(--ITSports-color-green);
    width: 50px;
    height: 50px;
    color: white;
    right: 0;
    background-image: url("https://itsports.ru/static/assets/img/arrowR.png");
    background-size: 20px;
    background-position: center;
    background-repeat: no-repeat;
    box-shadow: 0 2px 1px 0 var(--ITSports-color-arsh);
    &::after {
      display: none;
    }

    @media (max-width: 1000px) {
      width: 40px;
      height: 40px;
    }
    @media (max-width: 768px) {
      width: 30px;
      height: 30px;
    }
  }
}
.swiper-button-prev {
  &.what-about-us-swiper {
    position: relative;

    left: 0;
    background-color: var(--ITSports-color-green);
    width: 50px;
    height: 50px;
    color: white;
    transform: rotate(180deg);
    background-image: url("https://itsports.ru/static/assets/img/arrowR.png");
    background-size: 20px;
    background-position: center;
    background-repeat: no-repeat;
    box-shadow: 0px -2px 1px 0px var(--ITSports-color-arsh);
    @media (max-width: 1000px) {
      width: 40px;
      height: 40px;
    }
    @media (max-width: 768px) {
      width: 30px;
      height: 30px;
    }
    &::after {
      display: none;
    }
  }
}
.swiper-pagination {
  &.what-about-us-swiper {
    position: relative;
    width: 80% !important;

    display: flex;
    align-items: center;
    justify-content: space-evenly;
    .swiper-pagination-bullet {
      border-radius: 0;
      width: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 20px;
      width: 50px;
      height: 50px;
      color: var(--ITSports-color-font);
      opacity: 1;
      background: none;
      @media (max-width: 1000px) {
        width: 40px;
        height: 40px;
      }
      @media (max-width: 768px) {
        width: 30px;
        height: 30px;
      }
    }
    .swiper-pagination-bullet-active {
      background: none;
      border: 1px solid var(--ITSports-color-green);
    }
  }
}
.swiper-wrapper {
  &.what-about-us-swiper {
    position: relative;
  }
}
.swiper-slide {
  &.what-about-us-swiper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 100%;
      object-fit: cover;
    }
  }

  &.swiper-tech {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 134px;
    height: 134px;
    img {
      width: 134px;
      height: 134px;
      object-fit: cover;
    }
  }
}
.what-about-us-swiper__slider-text {
  color: var(--ITSports-color-white);
  position: absolute;
  left: 50px;
  bottom: 130px;
  span {
    font-weight: 500;
    font-size: 24px;
    line-height: 140%;
  }
}

.mob-what {
  display: none;
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    position: relative;
  }
  &__bg {
    position: absolute;
    top: 123px;
    right: 4px;
    z-index: -9;
    height: 100%;
    width: 100%;
    padding: 0px 18%;
    @media (max-width: 480px) {
      padding: 0;
    }
    img {
      width: 100%;
      height: 100%;
    }
  }

  &__box {
    height: 270px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: -2;
      pointer-events: none;
    }
  }

  &__info {
    z-index: 9;
    width: 240px;
    margin: 0 auto;
    padding-left: 15px;
  }

  &__title {
    font-weight: 900;
    font-size: 24px;
    line-height: 140%;
    /* identical to box height, or 34px */
    padding-bottom: 20px;
    letter-spacing: 0.03em;
    text-transform: uppercase;
  }
  &__text {
    line-height: 20px;
  }
  .white {
    color: rgb(255, 255, 255);
  }

  .green {
    .mob-what__info {
      width: 200px;
      padding-left: 15px;
    }
  }
  .small {
    margin-top: 20px;
    .mob-what__info {
      padding-left: 34px;
    }
  }
}
