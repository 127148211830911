@import "common/variables.scss";

.bg-green-mask {
  background-color: $bgcolor-secondary;
  border-radius: 20px 20px 0 0;
}

.why-us {
  background-color: $bgcolor-main;
  border-radius: 20px;
  padding: 40px 5px 70px 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  img {
    width:80%;
  }
  &__title {
    @include block-title;
    color: white;
    margin-bottom: 60px;
    padding: 0 8px;
    @media (max-width: 1150px) {
      margin-bottom: 16px;
      font-size: 30px;
    }
    @media (max-width: 800px) {
      font-size: 27px;
    }
    @media (max-width: 720px) {
      font-size: 20px;
    }
    @media (max-width: 550px) {
      font-size: 16px;
    }
    @media (max-width: 380px) {
      font-size: 13.5px;
      font-weight: 600;
    }
  }
  &__reasons {
    display: flex;
    justify-content: space-evenly;
    align-items: flex-end;
    flex-wrap: wrap;

    &__item {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      width: 25%;
      padding: 8px;

      @media (max-width: 1200px) {
        width: 50%;
      }
      @media (max-width: 653px) {
        width: 100%;
      }

      &--image {
        justify-self: flex-start;
      }
      &--title {
        font-weight: 900;
        font-size: 1.25rem;
        line-height: 1.4;
        text-align: center;
        letter-spacing: 0.03em;
        text-transform: uppercase;
        color: $bgcolor-secondary;
        margin: 30px 0 15px;
      }
      &--descr {
        font-weight: 400;
        font-size: 1.125rem;
        line-height: 1.5;
        text-align: center;
        color: white;
        max-width: 100%;
      }
    }
  }
}

.why-us__reasons__item--descr {
  height: 80px;
}

.why-us__reasons__item,
.why-us__reasons__item--title,
.why-us__reasons__item--descr {
  transition: 0.3s ease-in-out;
}
.why-us__reasons__item {
  position: relative;
  width: 380px;
  height: 400px;
  border-radius: 20px;
  margin: 8px;
}
.why-us__reasons__item-mask:hover {
  opacity: 1;

  animation: forMask 0.3s linear forwards;
  img {
    
    animation: forMaskImg 0.2s linear forwards;
  }
  .why-us__reasons__item--title {
    animation: forMaskTitle 0.3s ease-out forwards;
  }
}

.why-us__reasons__item-mask {
  img {
    transition: all 0.3s;
  }

  opacity: 0;
  top: 0;
  width: 380px;
  height: 400px;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  background-color: #e6d327;
  .why-us__reasons__item--title,
  .why-us__reasons__item--descr {
    color: var(--ITSports-color-font);
  }
  .why-us__reasons__item--descr {
    padding: 0 10px;
  }
}

@keyframes forMask {
  0% {
    height: 400px;
    background-color: #e6d327;
  }

  30% {
    height: 455px;
    background-color: #b7a81d;
  }
  100% {
    height: 450px;
    background-color: #e6d327;
  }
}

@keyframes forMaskImg {
  0% {
    width: 80%;
  }

  40% {
    width: 75%;
  }
  100% {
    width: 80%;
  }
}

@keyframes forMaskTitle {
  0% {
    font-size: 1.25rem;
  }

  70% {
    font-size: 1.5rem;
  }
  100% {
    font-size: 1.4rem;
  }
}

.why-us__reasons {
  @media (max-width: 1840px) {
    display: none;
  }
}

.why-us__title {
  @media (max-width: 1840px) {
    margin-bottom: 0px;
  }
}

.whyUs-swiper {
  width: 100%;
  height: 500px;
  @media (min-width: 1840px) {
    display: none;
  }
  .why-us__reasons__item-mask {
    z-index: 1;
    width: 100%;
    position: fixed;
    bottom: 0 !important;
    top: inherit;
    @media (max-width: 768px) {
      display: none;
    }
  }
}
@keyframes forMaskSw {
  0% {
    height: 400px;
    background-color: #e6d327;
  }

  30% {
    height: 420px;
    background-color: #a79815;
  }
  100% {
    height: 400px;
    background-color: #e6d327;
  }
}

.whyUs-swiper__box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.swiper-button-next.whyUs {
  background-color: var(--ITSports-color-green);
  width: 30px;
  height: 30px;
  color: white;

  background-image: url("https://itsports.ru/static/assets/img/arrowR.png");
  background-size: 20px;
  background-position: center;
  background-repeat: no-repeat;
  right: 2%;
  box-shadow: 0 2px 1px 0 var(--ITSports-color-arsh);
  &::after {
    display: none;
  }
}

.swiper-button-prev.whyUs {
  background-color: var(--ITSports-color-green);
  width: 30px;
  height: 30px;
  color: white;
  transform: rotate(180deg);
  background-image: url("https://itsports.ru/static/assets/img/arrowR.png");
  background-size: 20px;
  background-position: center;
  background-repeat: no-repeat;
  left: 2%;
  box-shadow: 0 -2px 1px 0 var(--ITSports-color-arsh);
  &::after {
    display: none;
  }
}
