

.main {
   width: 100%;
   margin: 0 auto;
   min-height: 500px;
   position: relative;
   height: 100vh;
   // .main__bg
   overflow: hidden;
   border-radius: 0 0 20px 20px;
   &__bg {
      text-align: center;
      position: relative;
   }

   img{
      pointer-events: none;
   }

   // @media (min-width:767px) {
   //    min-height: 500px;
   // }
}
.bg-main {
   overflow: hidden;
   position: relative;
   width: 100%;
   height: 100%;

   background-color: var(--ITSports-color-blue);

   
   &__gif{
      position: absolute;
      top: 0;
      left: 0;bottom: 0;right: 0;
      width: 100%;
      height: 100%;
      transition: all 0.3s ;
      object-fit: cover;
      background-color: var(--ITSports-color-green);
      
    
   }

   &__itsports{
      position: absolute;
      top: 0;left: 0;
      width: 100%;
      height: 100%;
      
   }
   // .bg-main__transparent

   &__transparent {
      position: absolute;
      height: 100%;
      width: 100%;
      border-radius: 0 0 20px 20px;
      z-index: 6;
      overflow: hidden;
      
      transition: all 0.3s;
      @media (max-width:992px){
         z-index: 9;
         background: rgba(0, 0, 0, 0.589);
         
      }
      @media (max-width:480px){
         height: 100%;
      }
      
   }
}


.itsports-bg__container{
   position: relative;
   width: 100%;
   height: 100%;
   img{
      width: 100%;
      height: 115%;
      @media (max-width:992px){
         display: none;
         height: 100%;
      }
   }
}
.itsports-bg__mob-img{
   display: none;
   @media (max-width:992px) {
      display: block;
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      img{
         display: block!important;
         object-fit: contain;
         width: 87%;
         margin: 0 auto;
      }
   }
}



 
 

.awesome-logo-container{
   position: absolute;
   width: 100%;
   height: 100%;
   pointer-events: none;
   z-index: 999999999;
   top: 0;
   left: 0;
}

.green-awesome-logo{
   pointer-events: all;
   cursor: pointer;
   position: relative;
   top: 37%;
   left: 47.5%;
   
   font-size: 24px;
   letter-spacing: 0;
   font-weight: 600;
   color: rgba(84, 85, 84, 0.363);
   background-color: var(--ITSports-color-green);
   border-radius:50% ;
   
   mix-blend-mode: normal!important;
   width: 200px;
   height: 200px;
   display: flex;
   align-items: center;
   justify-content: center;
   //overflow: hidden;
   z-index: 10;
   &.activetbg{
      background-color: var(--ITSports-color-blue);
   }
   
}  

.bg-main__text{
   text-align: left;
   font-size: 22px;
   line-height: 30px;
   text-transform: uppercase;
   font-weight: 900;
   color: var(--ITSports-color-white);
   position: absolute;
   top: 50%;
   transform: translateY(-50%);
   left: 6.6%;
   z-index: 999;
   letter-spacing: 0.12rem;
   opacity: 1;
   transition: opacity 0.8s;
   button{
      width: fit-content;
   }
   span{
      
      color: var(--ITSports-color-white);
   }
   

   @media (max-width:992px){
      font-size: 20px;
      line-height: 28px;
      
      width: 40%;
   }
  
   @media (max-width:768px){
      width: 100%;
      top: auto;
      transform: translateY(0);
      bottom: 20%;
   }
   @media (max-width:480px){
      font-size: 18px;
      
      left: 20px;
      padding-right: 20px;
      width: 100%;
      max-width: calc(100vw - 40px);
   }
}
.bg-main__text-span{
   max-width: 90%;
   padding-right: 20px;
   white-space:nowrap;
   font-size: 33px;
   line-height: 50px;
   @media (max-width:1020px) {
      font-size: 23px;
      line-height: 30px;
   }
   @media (max-width:480px) {
      font-size: 18px;
      line-height: 25px;
   }
}





.bottom-main{
   text-align: center;
   position: absolute;
   bottom: 0;
   left: 0;
   right: 0;
   z-index: 5;
   background-color: var(--ITSports-color-green);
   text-transform: uppercase;
   height:12%;
   font-weight: 900;
   font-size: 30px;
   display: flex;
   align-items: center;
   justify-content: center;
   letter-spacing: 0.12rem;
   line-height: 1.2rem;
   padding: 0 8px;
   
   ::after{
      content: '';
      background-color: var(--ITSports-color-green);
      width: 100%;
      height: 25px;
      position: absolute;
      bottom: 98%;
      left: 0;
      @media (max-width:992px) {
         display: none;
      }
   }
}


.bottom-main__span{
   color: var(--ITSports-color-font);
   line-height: 110%;
   display: block;
   padding-bottom: 20px;
   @media (max-width:1325px) {
      font-size: 20px;
   }
   @media (max-width:1120px) {
      font-size: 16px;
   }
   @media (max-width:992px) {
      padding-bottom: 0;
   }
   @media (max-width:400px) {
      font-size: 15px;
      
   }
  
}


.preloader {
   
   opacity: 0;
   transition: all 0.3s ;
   position: relative;
   
   width: 100%;
   height: 100%;
	
   background: #16192B;
   z-index: 9;
   border-radius: 50%;
 }

.preloader.activel::after{
   .green-awesome-logo-gif{
      background-color: #16192B;
   }
   //position: relative;
   // content: '';
   // border-radius: 50%;
   // border: 6px solid var(--ITSports-color-green);
   // border-top-color: #16192B;
   // width: 100%;
   // height: 100%;
   // animation: loading 2.1s linear forwards;
}


.preloader__container {

display: flex;
align-items: center;
justify-content: center;
position: relative;
width: 100%;
   height: 100%;
}
.progress-ring{
   width: 100%;
   height: 100%;



   
}




.bottom-vis{
   height: 88%;
}


.bg-main__text-btn{
   font-weight: 700;
   font-size: 20px;
   letter-spacing: 0.12rem;

   display: flex;
   align-items: center;
   justify-content: center;
   background-color: var(--ITSports-color-green);
   color:var(--ITSports-color-font);
   text-transform: uppercase;
   cursor: pointer;
   width: 346px;
   margin-top: 40px;
   height: 70px;
   max-width: calc(100vw - 40px);
   transition: opacity 0.2s;
   &:hover{
      opacity: 0.8;
   }
   @media (max-width:992px) {
      margin-top: 20px;
      height: 64px;
      font-size: 18px;
      width: 320px;
      height:45px;
   }
   
}


.main-img{
   
   position: absolute;
   z-index: 999;
   right: 6%;
   height: calc(100% - 200px);
   top: 80px;
   display: flex;
   align-items: center;
   justify-content: center;
   width: 40%;
   &__img{
      
      height: 100%;
      object-fit: contain;
      width: 100%;
      animation: anime 8s infinite;
   }

   

   @media (max-width:768px) {
      width: 100%;
      right: auto;
      height: 272px;
      left: 50%;
      top: 35%;
      transform: translate(-50%,-50%)
   }
}
.green-span{
   color: var(--ITSports-color-green)!important;
   white-space: nowrap;
}



@keyframes anime {
   0% {
		-webkit-transform: translateY(0px);
		transform: translateY(0px);
	}
	50% {
		-webkit-transform: translateY(-20px);
		transform: translateY(-20px);
	}
	100% {
		-webkit-transform: translateY(0px);
		transform: translateY(0px);
	}
}


@keyframes animeMob {
   0% {
		-webkit-transform: translate(-50%,-50%);
		transform: translate(-50%,-50%);
	}
	50% {
		-webkit-transform: translate(-50%,-20px);
		transform: translate(-50%,-45%);
	}
	100% {
		-webkit-transform: translate(-50%,-50%);
		transform: translate(-50%,-50%);
	}
}