.confa {
  position: relative;
  width: 87%;
  margin: 0px auto 80px;
  border-radius: 20px;
  padding: 40px;
  background-color: var(--ITSports-color-bg);
  color: var(--ITSports-color-white);
  transition: all 0.3s;
  overflow: hidden;
  
  @media (max-width: 600px) {
    padding: 16px;
    margin: 0px auto 40px;
  }

  @media (max-width: 480px) {
    width: 100%;
  }

  &__title {
    font-size: 3rem;
    font-weight: 900;
    line-height: 1.4;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    text-align: center;
    @media (max-width: 600px) {
      font-size: 32px;
    }
    @media (max-width: 480px) {
      font-size: 28px;
    }
  }
}

.lists-confa {
  height: 100%;
  width: 87%;
  margin: 0 auto;
  position: relative;
  padding: 100px 0;
  @media (max-width: 1851px) {
    display: none;
  }
  &__link {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
  }
  &__title {
    padding-bottom: 32px;
    font-weight: 500;
    line-height: 28px;
    font-size: 20px;
    letter-spacing: 1.4px;
    width: 120px;
    text-align: center;
  }
}

.lists-confa__list {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  left: -100%;
  top: 0;
  opacity: 0;
  z-index: 0;
  pointer-events: none;
  transition: all 0.3s;
  flex-wrap: wrap;
  position: absolute;
  @media (max-width: 1411px) {
    justify-content: center;
  }
}
.lists-confa__list.activet {
  left: 0;
  right: 0;
  opacity: 1;
  z-index: 9;
  pointer-events: all;
  position: relative;
}
.lists-confa__link:not(:last-child) {
  margin-right: 3%;
}
.lists-confa__link {
  position: relative;
  height: 400px;

  &::after {
    content: "";
    background-color: var(--ITSports-color-white);
    width: 40%;
    height: 2px;
    position: absolute;
    top: 14%;
    right: -27%;
  }
  &:last-child {
    &::after {
      display: none;
    }
  }
}
.lists-confa__date {
  color: var(--ITSports-color-font);
  margin-top: 32px;
  font-weight: 700;
  line-height: 28px;
  font-size: 16px;
  letter-spacing: 1px;
  text-align: center;
  text-transform: uppercase;
  padding: 16px 0;

  background-color: var(--ITSports-color-lightGrey);
}

.confa__btn {
  cursor: pointer;
  background-color: var(--ITSports-color-green);
  width: 50px;
  height: 50px;
  color: white;
  right: 0;
  background-image: url("https://itsports.ru/static/assets/img/arrowR.png");
  background-size: 20px;
  background-position: center;
  background-repeat: no-repeat;
  box-shadow: 0 2px 1px 0 var(--ITSports-color-arsh);
  position: absolute;
  right: 2.7%;
  top: 50%;

  transition: all 0.3s;
  @media (max-width: 1851px) {
    display: none;
  }
  &:hover {
    background-color: var(--ITSports-color-arsh);
  }
}
.confa__btn.activet {
  transform: rotate(-180deg);
  box-shadow: 0 -2px 1px 0 var(--ITSports-color-arsh);
}

.lists-confa__logo {
  position: relative;
  width: 303px;
  height: 166px;
  overflow: hidden;

  img {
    transition: all 0.3s;
    position: absolute;
    object-fit: contain;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.lists-confa__logo1 {
  opacity: 0;
  z-index: 2;
}
.lists-confa__logo2 {
  z-index: 0;
}
.lists-confa__date {
  transition: all 0.3s;
}

.lists-confa__confa {
  &:hover {
    .lists-confa__logo1 {
      opacity: 1;
    }
    .lists-confa__date {
      background-color: var(--ITSports-color-green);
    }
  }
}

.high-load-conf {
  img {
    height: auto;
  }
}

.confa-swiper {
  display: none;
  @media (max-width: 1851px) {
    padding: 100px 0;
    display: block;
  }

  @media (max-width: 943px) {
    padding: 32px 0;
    &::after {
      display: none;
    }
  }
  .lists-confa__link {
    margin-right: 0%;
    &::after {
      display: none;
    }
  }
  .lists-confa__confa {
    @media (max-width: 943px) {
      width: 60%;
      display: flex;
      align-items: center;
      flex-direction: column;
      .lists-confa__date {
        width: 100%;
      }
      .lists-confa__logo {
        width: 120%;
        img {
          object-fit: cover;
        }
      }
    }
  }
  .swiper-slide-active.lists-confa__link {
    .lists-confa__logo1 {
      opacity: 1;
    }
    .lists-confa__date {
      background-color: var(--ITSports-color-green);
    }
    &::after {
      display: block;
      content: "";
      background-color: var(--ITSports-color-white);
      width: 40%;
      height: 2px;
      position: absolute;
      top: 14%;
      right: -20%;
      @media (max-width: 992px) {
        display: none;
      }
    }
  }
}

.swiper-button-next.conf {
  background-color: var(--ITSports-color-green);
  width: 50px;
  height: 50px;
  color: white;

  background-image: url("https://itsports.ru/static/assets/img/arrowR.png");
  background-size: 20px;
  background-position: center;
  background-repeat: no-repeat;
  right: 0%;
  box-shadow: 0 2px 1px 0 var(--ITSports-color-arsh);
  &::after {
    display: none;
  }
  @media (max-width: 1200px) {
    width: 40px;
    height: 40px;
  }
  @media (max-width: 480px) {
    width: 30px;
    height: 30px;
  }
}

.swiper-button-prev.conf {
  background-color: var(--ITSports-color-green);
  width: 50px;
  height: 50px;
  color: white;
  transform: rotate(180deg);
  background-image: url("https://itsports.ru/static/assets/img/arrowR.png");
  background-size: 20px;
  background-position: center;
  background-repeat: no-repeat;
  left: 0%;
  box-shadow: 0 -2px 1px 0 var(--ITSports-color-arsh);
  &::after {
    display: none;
  }
  @media (max-width: 1200px) {
    width: 40px;
    height: 40px;
  }
  @media (max-width: 480px) {
    width: 30px;
    height: 30px;
  }
}
