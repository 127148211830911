@import 'common/variables.scss';
.dragn{
   width: 100%;
   height: 185px;
   padding-bottom: 32px;
   cursor: pointer;
}
.drop-area{
   
   text-align: center;
   height: 100%;
   width: 100%;
   border: 2px dashed var(--ITSports-color-font);
   display: flex;
   align-items: center;
   justify-content: center;
   font-size: 20px;
   z-index: 999999999999;
   color:var(--ITSports-color-font  );
   transition: all 0.2s;
   &.end-drop{
      background-color: rgba(0, 0, 0, 0.151);
   }
}

.form-vacancy__container{
   .drop-area{
      border: 2px dashed white;
      color: #fff;
      &.end-drop{
         background-color: rgba(218, 218, 218, 0.515);
      }
   }
}



