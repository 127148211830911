



.preloader-bg{
   position: absolute;
   top: 0;
   left: 0;
   bottom: 0;
   right: 0;
   width:100%;
   height:100%;
   background: var(--ITSports-color-blue);
   z-index: 99999999999;
   pointer-events: all;
 }
 
 .preloader-main {
  background: var(--ITSports-color-blue);

    position: fixed;
    top: 0;
    left: 0;
    width:100%;
    height:100%;
    
    display: flex;
    align-items: center;
    justify-content: center;
 
    
  }
  
  .preloader-main::after {
    width: 120px;
    height: 120px;
    content: "";
    transition: all 0.5s;
    border-radius: 50%;
    border: 4px solid var(--ITSports-color-green);
    border-top-color: var(--ITSports-color-blue);
    
    animation: loading 1s linear infinite;
   
  }
  
  @keyframes loading {
    0% {
      transform: rotate(0deg);
    }
    0% {
      transform: rotate(-360deg);
    }
  }
  


   .preloader-bg.main-loaded_hiding{
   opacity: 0;
   transition: opacity 0.5s ;
   pointer-events: none;
  }

