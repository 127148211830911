@import 'common/variables.scss';

.form-vacancy {
   opacity: 0;
   pointer-events: none;
   transition: all 0.3s;
   background-color: $bgcolor-main;
   border-radius:20px;
   padding: 20px 8px;
   display: flex;
   flex-direction: column;
   align-items: center;
   position: fixed;
   top: 50%;
   left: 50%;
   transform: translate(-50%,-50%);
   z-index: 999999999;
   color: white;
   max-height: 700px;
   height: 85%;
   width: 475px;
   
   @media (max-width:480px) {
     width: 90%;
  }
   h2{
      font-family: 'Inter';
      font-size: 16px;
      font-weight: 400;
      color: white;
      text-transform: none;
      @media (max-width:480px) {
         font-size:15px;
     }
   }
   &__container {
       display: flex;
       flex-direction: column;
       align-items: center;
       justify-content: space-between;
       
       height: 100%;
       padding-right: 5px;
       padding-bottom: 2.5%;
      width:95%;
       overflow-y: auto;
       overflow-x: hidden;
   /* полоса прокрутки (скроллбар) */
&::-webkit-scrollbar {
   width: 3px; /* ширина для вертикального скролла */
   height: 3px; /* высота для горизонтального скролла */
   background-color: #e9e9e94f;
   border-radius: 4px;
}

/* ползунок скроллбара */
&::-webkit-scrollbar-thumb {
   background-color: var(--ITSports-color-green);
   border-radius: 4px;
}

&::-webkit-scrollbar-thumb:hover {
   background-color: var(--ITSports-color-arsh);
   
}
   }
   &__title {
       @include block-title;
       font-size: 2rem;
       text-align: center;
       margin-bottom: 5%;
       color: white;
       @media (max-width:653px) {
           font-size: 1.6rem;
           padding: 0 14px;
       }
       @media (max-width:480px) {
           font-size: 1.3rem;
           padding: 0 25px;
       }
       @media (max-width:400px) {
           font-size: 1rem;
           padding: 0 35px;
       }
   }
   &__input {
         width: 100%;
         background: transparent;
         border: none;
         border-bottom: 1px solid white;
         font-weight: 500;
         font-size: 1.125rem;
         line-height: 1.4;
         margin-bottom: 20px;
         color: white!important;
        

         &:focus {
            outline: none;
         }
   }
   &__textarea {
       border: 1px solid white;
       border-radius: 5px;
       padding: 10;
   }
   &__button {
       max-width: 300px;
       padding: 18px 84px;
       background-color: var(--ITSports-color-green);
       color: var(--ITSports-color-font);
       font-size: 1.125rem;
       font-weight: 700;
       line-height: 1.125rem;
       text-transform: uppercase;
       display: flex;
       align-items: center;
       justify-content: center;
   }
}

.grey-bg{
   opacity: 0;
   transition: all 0.3s ease-in-out 0.2s;
   position: absolute;
   top: 0;
   left: 0;
   right: 0;
   bottom:0;
   height: 100%;
   background-color: rgba(0, 0, 0, 0.671);
   z-index: 99;
   pointer-events: none;
   -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}


.form-vacancy__close-button{
   img{
      filter: invert(100%); 
      -webkit-filter:invert(100%);
   }
   position: absolute;
   top: 2%;
   right: 3%;
}
.grey-bg.activet{
   opacity: 1;
   pointer-events: all;
   cursor: pointer;
}
.form-vacancy.activet{
   opacity: 1;
   pointer-events: all;
}

.form-vacancy .dragn{
   padding-bottom: 3%;
   max-height: 140px;
   min-height: 80px;
}


.form-a{
   display: flex;
   align-items: center;
   width: 100%;
   margin-bottom: 32px;
}
.form-a__input{
   margin-right: 8px;
   width: 16px;
   height: 16px;
   
}
.form-a__href,.form-a__span{
   color: inherit;
}
.form-a__href{
   border-bottom: 1px solid white;
}

.form-vacancy__input{
   @media (max-width:400px) {
      font-size: 15px;
      
   }
   
}

.inp-vac-hh{
   @media (max-width:420px) {
      font-size: 11px;
      
   }
}


.form-vacancy__container-file-input{
   width: 100%;
   position: relative;
   
}
.form-vacancy__file-input{
   cursor: pointer!important;
   max-height: 140px;
   min-height: 80px;
   width: 100%;
   background-color: var(--ITSports-color-blue);

   position: absolute;
   left: 0;
   bottom: 0;
   top: 0;
   right: 0;
   opacity: 0;

}


.form-vacancy__title-its{
   text-align: center!important;
   text-transform: uppercase!important;
   font-size: 22px!important;
   line-height: 28px!important;
   font-weight: 700!important;
   padding: 14px;
   @media (max-width:480px) {
      font-size: 16px!important;
   }
}

.form-vacansy__area-p{
   margin-top: 8px;
width: 100%;
padding: 5px;
border: 1px solid #ffffff;
   border-radius: 5px;
   margin-bottom: 30px;
}
.form-vacancy__area{
   font-weight: 500;
   font-size: 1.125rem;
   line-height: 1.4;
   color: #ffffff;
   background-color: inherit;
   width: 100%;
   
}