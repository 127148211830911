.burger {
  display: none;
}
.burger-exit {
  display: none;
}
.header {
  position: absolute;
  top: 20px;
  left: 5.8%;
  right: 5.8%;
  z-index: 999999999;
  letter-spacing: 0.3rem;
  white-space: nowrap;
  @media (max-width: 1450px) {
    font-size: 12px;
    letter-spacing: 0.15rem;
  }

  
  @media (max-width: 1185px) {
    font-size: 10px;
    letter-spacing: 0.12rem;
  }
  @media (max-width: 1064px) {
    font-size: 10px;
    letter-spacing: 0.055rem;
  }
  @media (max-width: 992px) {
    top: 16px;
    left: 4.8%;
    right: 4.8%;
  }
  @media (max-width:480px) {
    left: 3.8%;
    right: 3.8%;
  }
  // .header__container

  &__container {
    display: flex;
    align-content: center;
    justify-content: space-between;
  }

  // .header__logo

 

  &__menu-and-lang {
    width: 76%;
    justify-content: flex-end;
    display: flex;
    align-items: center;
    position: relative;
    @media (max-width: 992px) {
      .burger {
        cursor: pointer;
        position: absolute;

        right: -8px;
        top:0;
        display: block;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

        &__container {
          position: relative;
          width: 28px;
          height: 28px;
        }
        img {
          position: absolute;
        }
      }
    }
    @media (max-width: 480px) {
      .burger {
        cursor: pointer;
        position: absolute;

        right:0px;
        top: 0%;
        display: block;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
      }
    }
  }
  // .header__menu

  &__menu {
    padding-right: 15px;
    padding-left: 15px;
    align-items: center;
    display: flex;
    
    flex-wrap: nowrap;
    @media (max-width: 1100px) {
      width: 100%;
      justify-content: space-between;
    }
    @media (max-width: 992px) {
      padding-right: 0px;
      position: fixed;
      flex-direction: column;
      pointer-events: none;
      height: 0px;
      order: 2;
      transition: all 0.3s;
      align-items: center;
      justify-content: center;
      color: var(--ITSports-color-font);
      border-radius: 0 0px 20px 20px;
      top: 0;
      left: 0;
      right: 0;
      width: 100%;
      overflow: hidden;
      .burger-exit {
        position: absolute;
        right: 3.8%;
        top: 20px;
        display: block;
        background-image: url("https://itsports.ru/static/assets/img/exit.png");
        background-position: center;
        background-repeat: no-repeat;
        width: 22px;
        height: 22px;
      }
      &.activet {
        pointer-events: all;
        height: 320px;
        background: var(--ITSports-color-green) !important;
        z-index: 99999999999999999;
      }
    }
  }

  // .header__lang

  &__lang {
    color: var(--ITSports-color-green);
    padding-left: 15px;
    border-left: 2px solid var(--ITSports-color-white);
    font-weight: 600;
    text-transform: uppercase;
    transition: color 1.2s;
    width: 46.6px;
    span {
      
      transition: color 1.2s;
      cursor:pointer;
    }
    &.activet {
      border-left: 2px solid var(--ITSports-color-green);
      span {
        color: var(--ITSports-color-font);
        
      }
      @media (max-width: 992px) {
        border-left: none;
      }
    }
    @media (max-width: 992px) {
      position: absolute;
      border-left: none;
      padding-left: 0px;
      padding-right: 0px;
      
      display: flex;
      align-items: center;
      justify-content: center;
      
      top: -6px;
      right: 25px;
      span {
        padding: 10px 10px;
      }
    }
  }
}
.menu-header {
  // .menu-header__link

  &__link {
    color: var(--ITSports-color-white);
    text-transform: uppercase;
    font-weight: 600;
    transition: color 0.3s;
    font-size: 12px;
    cursor: pointer;
    &:not(:last-child) {
      margin-right: 12px;
      
      @media (max-width: 992px) {
        margin-right: 0;
        margin-bottom: 24px;
      }
    }

    @media (max-width: 1276px) {
      font-size: 11px;
      letter-spacing: 0.025rem;
    }
    @media (max-width: 1156px) {
      font-size: 10px;
      letter-spacing: 0.02rem;
    }
    @media (max-width: 992px) {
      font-weight: 800;
      color: var(--ITSports-color-font);
      font-size: 18px;
      
    }
    @media (min-width: 993px) {
      &:hover {
        color: var(--ITSports-color-green);
      }
      
    }
    
    
  }
}

.back_to_top {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 80px;
  right: 40px;
  z-index: 9999;
  width: 50px;
  height: 50px;
  text-align: center;
  line-height: 30px;
  background: rgba(204, 204, 204, 0.521);
  cursor: pointer;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  transition: all 0.5s;
  img {
    width: 20px;
    height: 30px;
  }
  @media (max-width: 992px) {
    bottom: 40px;
  }
}

.back_to_top:hover {
  background: var(--ITSports-color-green);
}

.back_to_top-show {
  pointer-events: all;
  opacity: 1;
}

// .preloader-main {
//   opacity: 1;
//   transition: all 0.3s;
//   position: fixed;
//   top: 0;
//   left: 0;
//   bottom: 0;
//   right: 0;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   background: rgb(5, 5, 5);
//   z-index: 99999999999999;
// }

// .preloader-main::after {
//   content: "";

//   border-radius: 50%;
//   border: 6px solid var(--ITSports-color-green);
//   border-top-color: #000000;
//   width: 80px;
//   height: 80px;
//   animation: loading 1s linear infinite;
// }

// @keyframes loading {
//   0% {
//     transform: rotate(0deg);
//   }
//   0% {
//     transform: rotate(-360deg);
//   }
// }

// .loaded_hiding .preloader-main {
//   opacity: 0;
// }

// .loaded .preloader-main {
//   display: none;
// }

.header__logo-img {
  opacity: 0;
  transition: opacity 1.2s;
  height: 50px;
  @media (max-width:992px) {
    height: 30px;
  }
}
.header__logo-img.activet {
  opacity: 1;
  position: absolute;
  left: 0;
}
.header.activet {
  .menu-header__link {
    opacity: 0;
    transition: opacity 1.2s;
    color: var(--ITSports-color-font) !important;
  }
}

.burger__logos {
  opacity: 1;
  transition: all 0.8s;
}
.burger__logos.activet {
  opacity: 0;
  position: absolute;
  left: 0;
}
.header__itscout{
img{
  width: 130px;
  object-fit:cover ;
}


}


.header__itscout-bigimg{
  display: block;
  @media (max-width: 992px) {
    display: none;
  }
}
.header__itscout-smallimg{
  display: none;
  @media (max-width: 992px) {
    display: block;
  }
}