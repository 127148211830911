

.descr-title{
   margin-bottom: 30px;
   h2{
      padding: 40px 15px 0 15px;
      font-family: 'Roboto Flex';
font-style: normal;
font-weight: 900;
font-size: 48px;
line-height: 140%;
/* or 67px */

text-align: center;
letter-spacing: 0.03em;
text-transform: uppercase;

color: #16192B;
font-stretch: 150;
@media (max-width:767px) {
   font-size:28px;
   padding: 30px 15px 0 15px;
   margin-bottom: -15px;
}
   }
}

.descr-cntnr{
   display: flex;
   align-items: flex-start;
   justify-content: space-between;
   width: 100%;

   padding-bottom: 70px;

   
   @media (max-width:767px) {
      display: none;
   }
}
.descr-option{
width: 33.3333%;

white-space: pre-line;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;


font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 150%;
/* or 27px */

text-align: center;
letter-spacing: 0.03em;

color: #16192B;


padding: 20px 11px 0 11px;
}

.descr-option__img{
   box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.356);
   border-radius: 8px;
   overflow: hidden;
   width: 100%;
      height: 100%;
      
   img{
      
      width: 100%;
      height: 100%;
      object-fit: cover;
   }

   
}


.swiper-button-next {
   &.its-swiper-button-next {
      position: relative;
      background-color: var(--ITSports-color-green);
      width: 40px;
      height: 40px;
      color: white;
      right: 0;
      background-image: url("https://itsports.ru/static/assets/img/arrowR.png");
      background-size: 15px;
      background-position: center;
      background-repeat: no-repeat;
      @media (max-width:480px) {
         width: 32px;
         height: 32px;
      }
      &::after {
         display: none;
      }
   
   }
   }
   .swiper-button-prev {
      &.its-swiper-button-prev {
      position: relative;
   
      left: 0;
      background-color: var(--ITSports-color-green);
      width: 40px;
      height: 40px;
      color: white;
      transform: rotate(180deg);
      background-image: url("https://itsports.ru/static/assets/img/arrowR.png");
      background-size: 15px;
      background-position: center;
      background-repeat: no-repeat;
      @media (max-width:480px) {
         width: 32px;
         height: 32px;
      }

      &::after {
         display: none;
      }
      }
      
   }
.its-desct-mob{
   position: relative;
   display: none;
   @media (max-width:767px) {
      display: block;
   }
}
.its-descr-swiper.swiper-slide{
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 150%;
/* or 24px */
white-space: pre-line;
text-align: center;
letter-spacing: 0.03em;

color: #16192B;
padding:0 15px  30px 15px;

}
   .its-desct-mob__btns{
      position: absolute;
      top: 0;
      left: 5px;
      right: 5px;
      height: 40%;
      display: flex;
      align-items: center;
      justify-content: space-between;
   }


   .descr-option__text{
      padding-top: 20px;
      @media (max-width:992px) {
         font-size: 16px;
      }
      @media (max-width:767px) {
         margin-top: -5px;
      }
   }