$main-font-color: #300C66;
$bgcolor-main: #300C66;
$bgcolor-secondary: #e6d327;

@mixin block-title {
    font-size: 3rem;
    font-weight: 900;
    line-height: 1.4;
    letter-spacing: 0.03em;
    text-transform: uppercase;
}