.itsdoc{
   border-radius: 20px 20px 0px 0px ;
   overflow: hidden;
   background: #16192B;
   padding: 70px 15px;
   @media (max-width:360px) {
      padding: 40px 15px; 
   }
}


.itsdoc__title{
   font-family: 'Roboto Flex';
   font-style: normal;
   font-weight: 900;
   font-size: 48px;
   line-height: 140%;
   /* or 67px */

   text-align: center;
   letter-spacing: 0.03em;
   color: #FFFFFF;
   @media (max-width:767px) {
      font-size: 28px;
      line-height: 114%;   
   }
   @media (max-width:360px) {
      font-size: 26px;  
   }
}


.itsdoc__description{
   font-family: 'Inter';
   font-style: normal;
   font-weight: 500;
   font-size: 18px;
   line-height: 150%;
   /* or 27px */

   text-align: center;
   letter-spacing: 0.03em;

   color: #FFFFFF;


   max-width: 565px;
   width: 100%;
   margin: 0 auto;

   padding: 20px 0 40px 0;


   @media (max-width:767px) {
      font-size: 16px;  
   }

   
}


.itsdoc__btn{
   display: flex;
   flex-direction: row;
   justify-content: center;
   align-items: center;
   max-width: 456px;
   width: 100%;
   height: 64px;
   margin: 0 auto;
   background: var(--ITSports-color-green);
   


   a{
      font-family: 'Roboto Flex';
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 140%;
      /* identical to box height, or 28px */

      text-align: center;
      letter-spacing: 0.03em;
      text-transform: uppercase;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      color: #16192B;
      font-stretch: 150;
      height: 100%;
      width: 100%;
      padding: 0 5px;
      white-space: nowrap;
      @media (max-width:1150px) {
         font-size: 16px;
      }
      @media (max-width:960px) {
         font-size: 18px;
      }
   }
}

.itsdoc__container{
   height: max-content;
}
.itsdoc__list-descr{
   display: flex;
   align-items: flex-start;
   justify-items: center;
   margin: 0 -10px;
   


   @media (max-width:1150px) {
      
   }


   @media (max-width:960px) {
      flex-direction: column;
   }
}



.itsdoc__link-descr{
   width: 33.3333%;
   height: 100%;
   
   
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: space-between;
   padding: 0 10px;


   @media (max-width:960px) {
      width: 100%;
      padding-top: 40px;
   }
}


.itsdoc__list-btn{
   display: flex;
   align-items: flex-start;
   justify-items: center;
   margin: 0 -10px;

   @media (max-width:960px) {
      flex-direction: column;
      
   }
}



.itsdoc__link-btn{
   width: 33.3333%;
   height: 100%;
   
   
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: space-between;
   padding: 0 10px;

   @media (max-width:1150px) {
   }


   @media (max-width:960px) {
      
      
   }
}


.itsdoc__big{
   @media (max-width:960px) {
      display: none;
      
   }
}

.itsdoc__small{
   display: none;
   @media (max-width:960px) {
      display: block;
      
   }
}