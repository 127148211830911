@import 'common/variables.scss';

.form {
    background-color: $bgcolor-secondary;
    border-radius: 0 0 20px 20px;
    padding: 70px 0 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media (max-width:653px) {
        padding: 40px 0 60px;
    }
    @media (max-width:400px) {
        padding-left: 10px;
        padding-right: 10px;
    }
    &__container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    &__title {
        @include block-title;
        font-size: 2rem;
        text-align: center;
        margin-bottom: 40px;

        @media (max-width:653px) {
            font-size: 1.6rem;
            padding: 0 5px;
        }
        @media (max-width:480px) {
            font-size: 1.3rem;
            padding: 0 5px;
        }
        @media (max-width:400px) {
            font-size: 1rem;
            padding: 0 5px;
        }
    }
    &__input {
        width: 500px;
        background: transparent;
        border: none;
        border-bottom: 1px solid var(--ITSports-color-font);
        font-weight: 500;
        font-size: 1.125rem;
        line-height: 1.4;
        margin-bottom: 30px;
        color: var(--ITSports-color-font);
        @media (max-width:653px) {
            width: 100%;
        }
        

        &:focus {
            outline: none;
        }
    }
    &__textarea {
        border: 1px solid var(--ITSports-color-font);
        border-radius: 5px;
        padding: 10;
    }
    &__button {
        max-width: 300px;
        padding: 18px 84px;
        background-color: var(--ITSports-color-font);
        color: var(--ITSports-color-green);
        font-size: 1.125rem;
        font-weight: 700;
        line-height: 1.4;
        text-transform: uppercase;
        
    }
}





.form__container-file-input{
    width: 100%;
    position: relative;
    
 }
 .form__file-input{
    cursor: pointer!important;
    max-height: 153px;
    min-height: 80px;
    width: 100%;
    background-color: var(--ITSports-color-blue);
 
    position: absolute;
    left: 0;
    bottom: 0;
    top: 0;
    right: 0;
    opacity: 0;
 
 }
 