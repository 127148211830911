.container {
    width: 87%;
    margin: 0 auto;
}
@media (max-width:480px) {
    .container {
       width: 100%;
    }
}
.div-preloader-fetch{
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;right: 0;
    background-color: black;
}
.App{
    position: relative;
}