@import './common/reset.scss';
@import 'About.scss';
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Flex:opsz,wdth,wght@8..144,100,400;8..144,151,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800;900&display=swap');



*{
   //чистим все отступы и границы
   padding: 0;
   margin: 0;
   border: 0;

}
*,*:before,*:after{
   //меняет подсчет ширины блока
   -moz-box-sizing: border-box;
   -webkit-box-sizing: border-box;
   box-sizing: border-box;
}

//убираем линии обводки в некоторых браузерах
:focus,:active{outline: none;}
a:focus,a:active{outline: none;}


//перечисляем некоторые теги и добавляем значение block
nav,footer,header,aside{display: block;}

html,body{
   //уравнивает поведение тегов для разных браузеров
   height: 100%;
   width: 100%;
   font-size: 100%;
   line-height: 1;
   font-size: 15px;
   -ms-text-size-adjust: 100%;
   -moz-text-size-adjust: 100%;
   -webkit-text-size-adjust: 100%;
}


//просим наследовать теги семейство шрифта
input,button,textarea{font-family: inherit; }


//работаем с нюанасами некоторых браузеров

input::-ms-clear{display: none;}
button{cursor: pointer;
   padding: 0;
   border: none;
   font: inherit;
   color: inherit;
   background-color: transparent;}
button::-moz-focus-inner {padding:0;border:0;}
a, a:visited{text-decoration: none;}
a:hover{text-decoration: none;}
ul li{list-style: none;}
img{vertical-align: top;}

h1,h2,h3,h4,h5,h6{font-size: inherit;font-weight: 400;}

//---------------------------------------
._ibg {
   background-position: center;
   background-size: cover;
   background-repeat: no-repeat;
   position: relative;
}

._ibg img {
position: absolute;
top: 0;
left: 0;
width: 100%;
}
:root {
	--ITSports-color-black: #300C66;
   --ITSports-color-lightGrey: #F5F5F5;
	--ITSports-color-white: #ffffff;
	--ITSports-color-blue: #300C66;
	--ITSports-color-grey: #555E61;
   --ITSports-color-green: #e6d327;
   --ITSports-color-arsh:rgb(210, 185, 18);
   --ITSports-color-font: #000000;
   --ITSports-color-light-red: #E40606;
   --ITSports-color-bg: #300C66;
}
body{
   font-family: 'Roboto Flex', sans-serif;
   font-stretch: 150%;
   overflow-x: hidden;
}
.what-about-us-swiper__text,.swiper-pagination-bullet,.green-awesome-logo,.stack__plan__item--descr,.directions__columns__right--tab__text,.why-us__reasons__item--descr{
   font-family: 'Inter';
}
.form-a__span,.form-a__href,.drop-area,.mob-what__text ,.team-slider__name,.lists-confa__link,.vacancy__tabs__item__header--subtitle,.vacancy__tabs__item--active,.want-work__text,.contacts__leftside--item__values {
   font-family: 'Inter';
}

.drop-area{
 padding: 0 5px;   
}


input {
   border-radius: 0!important;
}
.main{
   -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
   
}


body {
   -webkit-user-select: none;
   -moz-user-select: none;
   -ms-user-select: none;
   user-select: none;
   }

