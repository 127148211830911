
.itsprice{
   position: relative;
   width: 100%;
   margin-bottom: 70px;
   @media (max-width:480px) {
      margin-bottom: 30px;
   }
}
.itsprice__container{
   width: 87%;
   margin: 0 auto;
   padding:48px 0;
   @media (max-width:767px) {
      padding:110px 0;
   }
   @media (max-width:480px) {
      padding:170px 0;
   }
}

.itsprice__bg{
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   z-index: -30;
   img{
      width: 100%;
      height: 100%;
      object-fit: cover;
   }
}

.itsprice__title{
   padding-bottom: 40px;
   h2{
      font-family: 'Roboto Flex';
      font-style: normal;
      font-weight: 900;
      font-size: 48px;
      line-height: 140%;
      /* or 67px */

      letter-spacing: 0.03em;
      text-transform: uppercase;

      color: #16192B;
      font-stretch: 150;

      padding-bottom: 20px;

      @media (max-width:480px) {
         padding-bottom: 30px;
         font-size: 28px;
         line-height: 114%;
         /* identical to box height, or 32px */

         text-align: center;
         letter-spacing: 0.03em;

      }
   }


   p{
      white-space: pre-line;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 140%;
      /* or 25px */

      letter-spacing: 0.03em;

      color: #000000;

      @media (max-width:480px) {
         white-space: normal;
         font-size: 16px;
         line-height: 150%;
         /* or 24px */

         text-align: center;
         letter-spacing: 0.03em;

         color: #16192B;

      }
   }
}


.itsprice__subtitle{
   font-family: 'Roboto Flex';
   font-style: normal;
   font-weight: 900;
   font-size: 32px;
   line-height: 140%;
   /* identical to box height, or 45px */

   letter-spacing: 0.03em;
   text-transform: uppercase;

   color: #16192B;
   font-stretch: 150;

   padding-bottom: 40px;

   @media (max-width:480px) {
         
      font-size: 20px;
      line-height: 140%;
      /* or 28px */

      text-align: center;
      letter-spacing: 0.03em;

   }


}


.itsprice__btn{
   button{
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 300px;
      height: 60px;
      background: #16192B;

      font-family: 'Roboto Flex';
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 140%;
      /* identical to box height, or 28px */

      text-align: center;
      letter-spacing: 0.03em;
      text-transform: uppercase;

      /* accent */

      color: var(--ITSports-color-green);
      font-stretch: 150;

      @media (max-width:480px) {
         width:100%;
      }
   }
}


.itsprice__bg-imgbig{
   display:block;
   @media (max-width:480px) {
      display:none;
   }
}
.itsprice__bg-imgsmall{
   display:none;
   @media (max-width:480px) {
      display:block;
   }
}



.itsprice__subtitle-alert{
   text-transform: none;
   white-space: pre-line;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 140%;
      /* or 25px */
   
      letter-spacing: 0.03em;

      color: #000000;

      @media (max-width:480px) {
         white-space: normal;
         font-size: 16px;
         line-height: 150%;
         /* or 24px */

         text-align: center;
         letter-spacing: 0.03em;

         color: #16192B;

      }
}