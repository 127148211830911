@import 'common/variables.scss';

.footer > .container { 
    display: flex; justify-content: space-between;
    align-items: center;
    @media (max-width:992px) {
        flex-direction: column;  
        justify-content: center;
        align-items: center;
        
    } 
}

.footer {
    
    background-color: $bgcolor-main;
    padding: 80px 0;
    @media (max-width:992px) {
        padding: 40px 0;      
        
    }
    &__logo {
        //max-width: 220px;
        max-height: 50px;
        height:100%;
        @media (max-width:992px){
        max-height: 30px;

        }
    }
    &__nav {
        display: flex;
        width: 76%;
        justify-content: space-around;
        flex-wrap: wrap;
        padding-left: 5px;
        @media (max-width:992px) {
            padding-top: 32px;
             width: 100%;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding-left: 0px;
        }
        &__item{
            display: flex;
            align-items: center;
            justify-content: center;
            @media (max-width:992px) {
                &:not(:last-child){
                    padding-bottom: 24px;
                }
            }
        }
        
        &__item--link {
            cursor: pointer;
            font-weight: 700;
            font-size: 1.125rem;
            line-height: 1.4;
            text-transform: uppercase;
            color: white;
            text-decoration: none;
            transition: all 0.3s;
            display: flex;
            align-items: center;
            &:hover{
                color: var(--ITSports-color-green);
            }
            @media (max-width:1200px) {
                font-size: 12px;
                padding-left: 0px;
            }
            @media (max-width:993px) {
                font-size: 12px;
                
            }
            @media (max-width:992px) {
                
                padding-left: 0;
                font-size: 1.125rem;
            }
        }
    }
}

.footer__itscout{
    img{
      width: 130px;
      object-fit:cover ;
    }
    
      @media (max-width: 1320px) {
        img{
          width: 100px;
          object-fit:cover ;
        }
      }
      @media (max-width: 1200px) {
        img{
          width: 80px;
          object-fit:cover ;
        }
      }
      @media (max-width: 1156px) {
        img{
          width: 77px;
          object-fit:cover ;
        }
      }
      @media (max-width: 992px) {
        img{
          width: 134px;
          object-fit:cover ;
        }
      }
    }
.footer__itscout-bigimg{
    display: block;
    @media (max-width: 992px) {
      display: none;
    }
  }
  .footer__itscout-smallimg{
    display: none;
    @media (max-width: 992px) {
        display: block;
      }
  }