@import './common/variables.scss';

.about {
    display: flex;
    justify-content: 'center';
    padding: 80px 0;
    @media (max-width:968px) {
        flex-direction: column;
        padding: 32px 0;
    }
    &__description {
        width: 42%;
        padding: 150px 130px 150px 0;
        @media (max-width:968px) {
            width: 100%;
            padding: 0;
            padding-bottom: 32px;
        }

        &__title {
            @include block-title;
            margin-bottom: 2rem;
        }
        &__text {
            font-weight: 500;
            font-size: 1.5rem;
            line-height: 1.4;
            letter-spacing: 0.03em;
            color: $main-font-color;
            margin-bottom: 2.5rem;
        }
    }
    
    &__pagination {
        display: flex;
        
        &__button {
            width: 50px;
            height: 50px;
            margin-right: 2rem;
            border: 0;
            font-size: 1.25rem;
            font-weight: 500;
            color: $main-font-color;

            &:hover {
                cursor: pointer;
                opacity: 0.7;
            }
        }
    }

    &__slider {
        width: 58%;
        @media (max-width:968px) {
            width: 60%;
            margin: 0 auto;
        }
        img{
            object-fit: cover;
            width: 100%;
        }
    }
}
.slider--prev__arrow{
    transform: rotate(180deg);
    pointer-events: none;
}
.slider--next__arrow{
    pointer-events: none;
}
.slider--prev, .slider--next { background-color: $bgcolor-secondary; }
.slider--next { margin-right: 0; }
.active { border: 1px solid $bgcolor-secondary; }