@import './common/variables.scss';

.stack {
    
    padding: 77px 0;
    background-color: $bgcolor-main;
    @media (max-width:992px) {
        padding: 40px 0 77px;
    }

    &__title {
        @include block-title;
        margin-bottom: 0;
        width: 65%;
        margin: 0 auto;
        color: white;
        @media (max-width:992px) {
            width: 90%;
        }
        @media (max-width:768px) {
            font-size: 32px;
        }
        @media (max-width:600px) {
            font-size: 24px;
        }
        @media (max-width:480px) {
            font-size: 18px;
        }
        &span {
            color: $bgcolor-secondary;
        }
    }
    &__plan {
        display: flex;
        justify-content: space-around;
        width:100%;
        padding: 100px 0;
        padding-left: 4%;
        @media (max-width:768px) {
            padding: 80px 0 56px;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            width:165px;
            margin: 0 auto;
        }
        @media (max-width:480px) {
            width:100px;
        }
        &__item {
            @media (max-width:768px) {
                padding-bottom: 16px ;
            }

            &--title {
                @include block-title;
                color: $bgcolor-secondary;
                font-size: 1.875rem;
                line-height: 1;
                margin-bottom: 10px;
                position: relative;
                @media (max-width:480px) {
                    font-size: 1.2rem;
                }
                &::before {
                    content: '';
                    position: absolute;
                    background-color: $bgcolor-secondary;
                    width: 26px;
                    height: 26px;
                    left: -50px;
                    @media (max-width:992px) {
                        left: -30px;
                        margin-top: 2px;
                        width: 20px;
                        height: 20px;
                    }
                    @media (max-width:480px) {
                        left: -30px;
                        margin-top: 0px;
                        width: 14px;
                        height: 14px;
                    }
                }
            }
            &--descr {
                font-size: 1.125rem;
                font-weight: normal;
                line-height: 1.4;
                letter-spacing: 0.03;
                color: white;
            }
        }
    }
    &__marquee {
        width: 100%;

        &--item {
            margin-right: 100px;
        }
    }
}

.first-line { text-align: left; }
.second-line { text-align: right; }
span { color: $bgcolor-secondary; }


