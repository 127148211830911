@import 'common/variables.scss';

.vacancy {
    padding: 80px 0;
    @media (max-width:600px) {
        padding: 40px 0;;
        
    }
    &__title {
        @include block-title;
        text-align: center;
        @media (max-width:600px) {
            font-size: 32px;

        }
    }
    &__tabs {
        min-width: 100%;

        &__item {
            padding: 20px 30px;
            padding-right: 50px;
            border-bottom: 1px solid $main-font-color;
            
            &__header {
                
                display: flex;
                justify-content: space-between;
                cursor: pointer;
                align-items: center;
                svg{
                    width: 23px!important;
                    height: 14px!important;
                    position: absolute;
                    right: 5%;
                    transition: all 0.3s;
                    
                }
                &--title {
                    transition: all 0.2s;
                    font-weight: 900;
                    font-size: 30px;
                    line-height: 1.6;
                    color: $main-font-color;
                    text-transform: uppercase;
                    @media (max-width:600px) {
                        font-size: 20px;
                    }
                    @media (max-width:480px) {
                        font-size: 18px;
                        padding-right: 10px;
                    }
            
                }
                &--subtitle {
                    font-weight: 700;
                    font-size: 25px;
                    line-height: 1.6;
                    color: $main-font-color;
                    @media (max-width:600px) {
                        font-size: 16px;
                    }
                }
            }
            
            &--active {
                
                &__title {
                    font-weight: 700;
                    font-size: 1.875rem;
                    line-height: 1.4;
                    text-transform: uppercase;
                    margin: 15px 0;
                    @media (max-width:600px) {
                        font-size: 18px;
                    }
                }
                &__text {
                    list-style: disc;
                    font-size: 1.5rem;
                    line-height: 1.4;
                    @media (max-width:600px) {
                        font-size: 14px;
                    }

                }
            }
        }
    }
}

.in-office-only {
    font-size: 20px;
    font-weight: 900;
    margin-top: 15px;
}

.active-svg-arrow{
    transform: rotate(-180deg);
    path{
        stroke: var(--ITSports-color-green);
    }
}

.vacancy__tabs__item__header{
    z-index: 111;
}
.vacancy__tabs__item{
    position: relative;
    overflow: hidden;
    transition: all 0.5s ease-in-out;
    
}
.vacancy__tabs__item--active{
    transition: all 0.4s ease-in-out ;
    pointer-events: none;
    position: absolute;
    padding-top: 10px;
    right: 30px;left: 30px;
    &.activet{
        
    }


    .want-work__button{
        margin-top: 16px;
        @media (max-width: 480px) {
            width: 100%;
        }
    }
}


.vcTborder{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 30px;
    border-bottom: 1px solid $main-font-color;
    cursor: pointer;
    transition: color 0.2s;
    position: relative;
    h2{
        padding-right: 20px;
    }
    .svgcnt{
        transform: rotate(-90deg);

    }
    svg{
        
        width: 23px!important;
        height: 14px!important;
    }
    &:hover{
        color: var(--ITSports-color-green);
        svg{
            path{
                stroke: var(--ITSports-color-green);
            }
        }
    }
}
