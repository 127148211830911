@import './common/variables.scss';

.directions {
    color: var(--ITSports-color-font);
    padding: 80px 0;
    @media (max-width:768px) {
        padding: 16px 0 40px;
    }
    &__title {
        @include block-title;
        margin-bottom: 30px;
        padding: 40px 0;
        @media (max-width:768px) {
            padding: 16px 0;
            text-align: center;
            margin-bottom: 28px;
        }
        @media (max-width:600px) {
            font-size: 32px;
        }
    
    }
    &__columns {
        
        display: flex;
        justify-content: space-between;
        @media (max-width:768px) {
            flex-direction: column;
        }
        &__left {
            width: 40%;
            margin-top:30px;
            height: fit-content;
            @media (max-width:768px) {
                margin-top:0;
                width: 100%;
                text-align: center;
            }
            &--item {
                padding: 0 0 16px 20px;
                width: 100%;
                font-weight: 900;
                font-size: 1.875rem;
                line-height: 1.4;
                letter-spacing: 0.03em;
                text-transform: uppercase;
                border-bottom: 1.4px solid var(--ITSports-color-font);
                margin-bottom: 35px;
                transition: all 0.6s;
                white-space: nowrap;
                @media (max-width:1520px) {
                    font-weight: 700;
                    font-size: 1.5rem;
                    padding: 0 0 10px 0;
                }
                @media (max-width:1172px) {
                    font-size: 1.3rem;
                }
                @media (max-width:1017px) {
                    font-size: 1.2rem;
                }
                @media (max-width:992px) {
                    
                    font-size: 1rem;
                }
                @media (max-width:480px) {
                    
                    font-size: 1rem;
                    margin-bottom: 16px;
                }
                &:hover { cursor: pointer; }
            }
        }
        &__right {
            
            width: 56%;
            display: flex;
            align-items: center;
            @media (max-width:768px) {
                width: 100%;
                text-align: center;
            }
            &--tab {
                max-width: 284px;
                min-width: 100%;
                min-height: 284px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                background-color: $bgcolor-secondary;
                border-radius: 20px;
                padding: 8px 5px;

                &__title {
                    @include block-title;
                    
                    font-size: 31px;
                    text-align: center;
                    margin-bottom: 20px;
                    transition: color 0.3s;
                    @media (max-width:600px) {
                        font-weight: 700;
                        font-size: 1.5rem;
                    }
                }
                &__text {
                    font-size: 1.125rem;
                    font-weight: 500;
                    line-height: 1.5;
                    text-align: center;
                    max-width: 60%;
                    margin: 0 auto;
                    transition: color 0.3s;
                    @media (max-width:600px) {
                        font-size: 1rem;
                    }
                    @media (max-width:480px) {
                        
                        max-width: 90%;
                    }
                }


                
            }
        }
    }
}
.active-tab {
    color: var(--ITSports-color-green);
    display: flex;
    align-items: center;
    
    @media (max-width:768px) {
        justify-content: center;
        
        border-bottom: 1.4px solid var(--ITSports-color-green);
    }
    
    &::before {
        
        content: '';
        position: relative;
        display: block;
        margin: auto 20px auto 0;
        width: 22px;
        height: 22px;
        background-color: $bgcolor-secondary;
        transition: all 0.9s;
        @media (max-width:820px) {
            
            margin: auto 12px auto 0;
        }
        @media (max-width:768px) {
            display: none;
            
        }
    }
}
@media (max-width:480px) {
    .directions__columns__right{
        width: 87%;
        margin: 0 auto;
    }
}


.directions__columns__right--tab{
    pointer-events: none;
    transition: opacity 0.4s ease 0s;
    
    opacity: 0;
    
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    @media (max-width:768px) {
        left: 0;
        top: 0;
        transform: translateY(0%);
    }
    &__title{
        color: var(--ITSports-color-green);
    }
    &__text{
        color: var(--ITSports-color-green);
    }
}
.directions__columns__right--tab.activet{
    transition: opacity 0.4s ease 0.4s;
    opacity: 1;
    // position: relative;
    // top: 0;
    // transform: translateY(0%);
}
.directions__columns__right{
    border-radius: 16px;
    overflow: hidden;
    position: relative;
    transition: height 0.4s ease 0.4s;
}
.directions__columns__right--tab .directions__columns__right--tab__title{
    transition: none;
    
}
.directions__columns__right--tab .directions__columns__right--tab__text{
    transition: none;
    
}

.directions__columns__right--tab.activet .directions__columns__right--tab__title{
     color: var(--ITSports-color-font);
    
}
.directions__columns__right--tab.activet .directions__columns__right--tab__text{
     color: var(--ITSports-color-font);
    
}


.activetbw{
    transform: translateY(0);
    top: 0;
}