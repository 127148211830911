.ourAwesomeTeam {
  width: 87%;
  margin: 0 auto;
  padding: 80px 0;
  @media (max-width:1500px) {
    padding-bottom: 10px;
  }
  
  @media (max-width: 768px) {
    padding-bottom: 0px;
  }
  @media (max-width: 600px) {
    padding-top: 80px;
  }
}

.ourAwesomeTeam__title {

  margin-bottom: 10px;
  font-size: 3rem;
  font-weight: 900;
  line-height: 1.4;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: var(--ITSports-color-font);
  text-align: center;
  
  @media (max-width: 600px) {
    font-size: 32px;
  }
  @media (max-width: 360px) {
    font-size: 28px;
  }
}

.team-slider__vidos-container {
  height: 100%;
  
  display: flex;
  align-items: center;
  justify-content: center;
  
  @media (max-width: 768px) {
    padding-bottom: 0px;
  }
}

.team-slider__vidos {
  z-index:-1;
  height: 100%;
  width: 100%;
  object-fit:  contain;
  //transition: all 0.3s;
  @media (max-width: 1100px) {
    margin-top:-65px;
  }
  @media (max-width: 768px) {
    margin-top: -32px;
  }
}

.team-swiper {
  height: 700px;
  

  .swiper-slider-team {
    position: relative;
    //transition: all 0.5s;
  }
  .swiper-button-next {
    background-color: var(--ITSports-color-green);
    width: 50px;
    height: 50px;
    color: white;

    background-image: url("https://itsports.ru/static/assets/img/arrowR.png");
    background-size: 20px;
    background-position: center;
    background-repeat: no-repeat;
    right: 37%;
    @media (max-width: 1315px) {
      right: 32%;
      width: 40px;
      height: 40px;
    }
    @media (max-width: 768px) {
      right: 10%;
    }
    box-shadow: 0 2px 1px 0 var(--ITSports-color-arsh);
    &::after {
      display: none;
    }
  }
  .swiper-button-prev {
    background-color: var(--ITSports-color-green);
    width: 50px;
    height: 50px;
    color: white;
    transform: rotate(180deg);
    background-image: url("https://itsports.ru/static/assets/img/arrowR.png");
    background-size: 20px;
    background-position: center;
    background-repeat: no-repeat;
    box-shadow: 0px -2px 1px 0px var(--ITSports-color-arsh);
    left: 36%;
    @media (max-width: 1315px) {
      left: 30%;
      width: 40px;
      height: 40px;
    }
    @media (max-width: 768px) {
      left: 10%;
    }
    &::after {
      display: none;
    }
  }

}

.swiper-slider-team__container {
  height: 100%;
  padding-bottom: 15px;
  position: relative;
  //transform: scale(0.9,0.9);
  transition: padding 0.3s;
}
.team-swiper.five-teammates {
  .swiper-slider-team__container {
    height: 600px;
    @media (max-width:1920px) {
      height: 640px;
    }
  }
  .team-slider__name {
    color:var(--ITSports-color-font);
    bottom: 20px;
    @media (max-width:1920px) {
      bottom: 60px;
    }
    @media (max-width:1800px) {
      bottom: 50px;
    }
    @media (max-width:1500px) {
      bottom: 100px;
    }
    @media (max-width:1133px) {
      bottom: 50px;
    }
    @media (max-width:1100px) {
      bottom: 70px;
    }
    @media (max-width:950px) {
      bottom: 85px;
    }
    
  }
  .team-slider__vidos {
    
    height: 520px;
    width: 100%;
    object-fit:  contain;
    //transition: all 0.3s;
    @media (max-width: 1100px) {
      margin-top:-15px;
    }
    @media (max-width: 768px) {
      margin-top: -32px;
    }
  }


  .swiper-slide-active{
    .swiper-slider-team__container{
      //height: 700px;
      padding-top: 40px;
      // transform: scale(1,1) translateY(20px);
      
    }
    
    .team-slider__vidos {
      
      //height: 650px;
      width: 100%;
      @media (max-width: 1100px) {
        margin-top:-15px;
      }
      @media (max-width: 768px) {
        margin-top: -32px;
      }
    }
    .team-slider__name{
      padding-top: 40px;
    }
  }
  }


.team-slider__name {
  position: absolute;
  height: 68px;
  width: 100%;
  text-align: center;
  left: 50%;
  transform: translateX(-50%);
  
  z-index:10;
  //line-height: 18px;
  transition: padding 0.3s;
  bottom: 8%;
  p:first-child {
    text-transform: uppercase;
    font-weight: 800;
    padding-bottom: 4px;
    padding-top: 4px;
  }
}

.team-swiper .swiper-slide {
  
  height: fit-content;
  
}

// three-teammates

// .one-teammates {
//   display: none !important;
//   @media (max-width: 768px) {
//     display: block!important;
//   }
//   img{
//     width: 100%;
//     object-fit: contain;
//   }
// }
.team-slider__vidos{
  background-color: var(--ITSports-color-white);
  
}

.swiper-slider-team__bg{
  position:absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  
  background:  var(--ITSports-color-white);
  top: -4px;
  bottom: -4px;
  left: -4px;
  right: -4px;


  &::after {
    
    pointer-events: none;
    content: "";
    transition: all 0.5s;
    border-radius: 50%;
    border: 4px solid var(--ITSports-color-green);
    border-top-color: var(--ITSports-color-white);
    width: 80px;
    height: 80px;
    animation: loading 1s linear infinite;
  
  }
  
  @keyframes loading {
    0% {
      transform: rotate(0deg);
    }
    0% {
      transform: rotate(-360deg);
    }
  }
}

.one-teammates{
  .swiper-slide {
    height: 100% !important;
  }
  .swiper-slider-team__container{
    padding-bottom: 65px;
    padding-top: 20px;
    
  }
  .swiper-slide-active .team-slider__name {
    position: absolute;
    height: 68px;
    
    text-align: center;
    left: 50%;
    transform: translateX(-50%);
    line-height: 18px;
    //transition: all 0.5s;
    p:first-child {
      text-transform: uppercase;
      font-weight: 800;
      padding-bottom: 4px;
      padding-top: 4px;
    }
  }

  .team-slider__name{
    @media (max-width:360px) {
      bottom:12%;
    }
  }
}

.swiper-slider-team__bg.noactive{
  display: none;
}


//===================================================================

.testvideo{
  width: 20px;
  height: 20px;
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  z-index: -60;
  pointer-events: none;
  opacity: 0;
}
