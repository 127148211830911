@import 'common/variables.scss';

.bg-dark-mask { background-color: $bgcolor-main; border-radius: 0 0 30px 30px; }

.contacts {
    background-color: var(--ITSports-color-green);
    border-radius: 20px 20px 0 0;
    padding: 0px;
    display: flex;
    align-items: center;
    @media (max-width:1200px) {
        padding: 40px 0;
    }
    @media (max-width:992px) {
        justify-content: center;
        flex-wrap: wrap;
    }
   

    &__title {
        @include block-title;
        margin-bottom: 100px;
        text-align: center;
        
        @media (max-width:600px) {
            font-size: 32px;
            margin-bottom: 32px;
        }
    }
    &__leftside {
        &--item {
            display: flex;
            justify-content: space-evenly;
            
            &__types > p {
                color: var(--ITSports-color-font);
                font-weight: 900;
                font-size: 1.875rem;
                line-height: 1.4;
                text-transform: uppercase;
                margin-right: 60px;
                margin-bottom: 40px;
                min-height: 42px;
                @media (max-width:600px) {
                    font-size: 1.2rem;
                    margin-right: 20px;
                    margin-bottom: 20px;
                }
                
            }
            &__values > p {
                color: var(--ITSports-color-font);
                a{
                    color: inherit;
                }
                padding-top: 4px;
                font-weight: 700;
                font-size: 1.5rem;
                line-height: 1.4;
                text-transform: uppercase;
                margin-bottom: 40px;
                min-height: 42px;
                @media (max-width:600px) {
                    font-size: 1rem;
                    margin-bottom: 20px;
                }
            }
        }
    }
}
.contacts__leftside{
    width: 50%;
    height: 100%;
    
    @media (max-width:1200px) {
        width: 100%;
    }
}
.contacts__rightside{
    width: 50%;
    height: 400px;
    display: flex;
    justify-content: center;
    @media (max-width:1200px) {
        width: 45%;
        overflow-x: hidden;
    }
    @media (max-width:992px) {
        width: 100%;
    }

    img{
        width:100%;
        object-fit: contain;
    }
}
.contacts__vidos {
    height: 100%;
    animation: anime 6s infinite;
}
.want-work {
    background-color: var(--ITSports-color-bg);
    border-radius: 20px 20px 0 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 90px 5px;
    @media (max-width:600px) {
        padding: 40px 5px;
    }
    &__title {
        @include block-title;
        color: white;
        display: flex;
        align-items: flex-end;
        @media (max-width:1120px) {
            flex-direction: column;
            align-items:center;
            
        }
        @media (max-width:992px) {
            
            
        }
        @media (max-width:668px) {
            font-size: 32px;
        }
        @media (max-width:480px) {
            font-size:24px;
        }
        img{
            padding-bottom: 2px;
            margin-left: 12px;
            height: 80px;
            @media (max-width:992px) {
                margin-left: 0px;
                padding-bottom: 0px;
                padding-top: 12px;
                width: 80%;
            }
            @media (max-width:480px) {
                width: 80%;
            }
        }
        
    }
    &__text {
        white-space: pre-line;
        font-weight: 500;
        font-size: 1.5rem;    
        line-height: 1.4;
        color: white;
        text-align: center;
        margin: 40px 0;
        @media (max-width:480px) {
            font-size:18px;
        }
    }
    &__button {
        padding: 20px 50px;
        background-color: var(--ITSports-color-green);
        font-weight: 700;
        font-size: 1.25rem;
        line-height: 1.4;
        text-align: center;
        text-transform: uppercase;
        cursor: pointer;
        color: var(--ITSports-color-font);
    }
}




@keyframes anime {
    0% {
         -webkit-transform: translateY(0px);
         transform: translateY(0px);
     }
     50% {
         -webkit-transform: translateY(-20px);
         transform: translateY(-20px);
     }
     100% {
         -webkit-transform: translateY(0px);
         transform: translateY(0px);
     }
 }
 
 
 @keyframes animeMob {
    0% {
         -webkit-transform: translate(-50%,-50%);
         transform: translate(-50%,-50%);
     }
     50% {
         -webkit-transform: translate(-50%,-20px);
         transform: translate(-50%,-45%);
     }
     100% {
         -webkit-transform: translate(-50%,-50%);
         transform: translate(-50%,-50%);
     }
 }