.itsabout{
   
   border-radius: 0px 0px 20px 20px;
   
   height: 100%;
   overflow: hidden;
}

.itsabout__container{
   min-height: 100vh;
   width: 100%;
   height: 100%;
   position: relative;
   @media (max-width:992px) {
      height: 100vh;
   }
}
.itsabout__bg{
   
   z-index: -30;
   position:absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   display:flex ;
   align-items: flex-start;
   justify-content: flex-end;
   img{
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: right ;
      
   }
   @media (max-width:992px) {
      height: 102%;
   }
   @media (max-width:480px) {
      width: 125%;
   }
}
.itsabout__info{

   width: 87%;
   margin: 0 auto;
   color: white;
   
   display: flex;
   flex-direction: column;
   height: 100%;
   align-items: flex-start;
   justify-content: flex-end;
   padding-top: 8%;
   h2{
      
      font-family: 'Roboto Flex';
      font-style: normal;
      font-weight: 900;
      font-size: 48px;
      line-height: 110%;
      /* or 67px */

      letter-spacing: 0.03em;
      text-transform: uppercase;

      color: #FFFFFF;
      font-stretch: 150;
   }


   @media (max-width:992px) {
      padding-top: 0;
   }
}
.itsabout__text{
   padding-top: 8%;
   @media (max-width:992px) {
      display: none;
   }
}
.itsabout__descr{
   white-space: pre-line;
   padding-top:30px ;
   max-width: 569px;
   font-family: 'Inter';
   font-style: normal;
   font-weight: 500;
   font-size: 18px;
   line-height: 140%;
   /* or 25px */
   padding-bottom: 40px;
   letter-spacing: 0.03em;
}


.itsabout-mob{
background-color: #16192B;

h2{
   font-family: 'Roboto Flex';
font-style: normal;
font-weight: 900;
font-size: 28px;
line-height: 114%;
/* or 32px */

text-align: center;
letter-spacing: 0.03em;
text-transform: uppercase;

color: #FFFFFF;
font-stretch: 150;
padding: 40px 10px 30px 10px;
}


display: none;

@media (max-width:992px) {
   display: block;
}
}


.itsabout-mob__descr{
   padding: 0 20px 40px 20px;
   white-space: pre-line;
   font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 150%;
/* or 24px */

text-align: center;
letter-spacing: 0.03em;

color: #FFFFFF;

}


.itsabout__logo{
   padding-top: 40px;
   @media (max-width:992px) {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content:center ;
      img{
         width: 283px;
      }
   }
   
}